import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  toggle(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.toggle("hidden")
    })
  }
}