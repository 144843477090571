import { Controller } from "stimulus"

export default class extends Controller {

  savedraft(event){
    event.preventDefault()
    let el_question_form = document.querySelector(".js-question-form")
    let el_question_events = document.querySelector(".js-question-events")

    const formData = new FormData(document.querySelector(".js-question-form"))

    const hashid = formData.get('question[hashid]')

    formData.append("question[commit_event]", "Guardar borrador") 
    const headers = new Headers({
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    )

      
    const url = `/admin/questions/${hashid}.json`
    const fetchOptions = {
      method: 'POST',
      headers,
      body: formData
    }

    fetch(url, fetchOptions)
    .then((response) =>{
      if(response.ok) {
          console.log("ok")
        return response.json()
      } else {
          throw "Error en la llamada Ajax";
      }
    })
    .then((json) => {
        if (json.hasOwnProperty('errors')) {
          let message_error = "Tiene los siguientes errores: "
          json.errors.forEach(error => {
            message_error = message_error + error
          })

          el_question_form.insertAdjacentHTML("afterbegin", 
          `<div class = "flash error " data-controller="showhide" data-target="showhide.element">
            <span> ${message_error} </span>
            <div class = "btn-cancel " data-action="click->showhide#toggle">
              <svg class = "fill-current h-6 w-6 " role="button" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Cerrar</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"></path>
              </svg>
            </div>
          </div>`);
          el_question_events.scrollIntoView(true); 
        } else {
          window.location.href = json.url;  
        }
      }
      )
    .catch(err => console.log(err))

  }
}