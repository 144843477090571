import Rails from 'rails-ujs'
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['doc_type', 'doc_id', 
    'name', 'id', 'email', 'phone']
  
  connect() {
    console.log('connect')
    this.nameTarget.disabled = false
  }

  search(event) {
    console.log('search')
    const doc_id = this.doc_idTarget.value
    const doc_type = this.doc_typeTarget.value
    const ajax_url = this.element.getAttribute('data-search-url')
    
    this.resetValues()
    
    if (doc_id && doc_type) {
      Rails.ajax({
        type: "GET",
        url: ajax_url,
        data: `doc_id=${doc_id}&doc_type=${doc_type}`,
        success: (data) => {
          this.refreshValues(data)
        }
      })
    }
    
  }
  
  refreshValues(data){
    if (data && data.length == 1){
      console.log(data[0])
      this.nameTarget.value = data[0].name
      this.idTarget.value = data[0].id
      this.nameTarget.value = data[0].name
      this.emailTarget.setAttribute('placeholder', data[0].email)
      this.phoneTarget.setAttribute('placeholder', data[0].phone)
    }
  }
  
  resetValues(){
    this.nameTarget.value = null
    this.idTarget.value = null
    this.emailTarget.setAttribute('placeholder', 'email@example.com')
    this.phoneTarget.setAttribute('placeholder', '9xxyyyzzz')
  }
  
}