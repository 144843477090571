// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("trix");
require("@rails/actiontext");
import "../src/choices.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../src/scss/application.scss";

import "controllers";
import "../src/submit_on_click.js";

document.addEventListener("turbolinks:load", function(event) {
  if (typeof ga === "undefined") return;
  if (typeof URL === "undefined") return ga("send", "pageview", event.data.url);
  try {
    const url = new URL(event.data.url);
    return ga("send", "pageview", url.pathname);
  } catch (error) {
    ga("send", "pageview", event.data.url);
  }
});
