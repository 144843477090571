import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  toggle(event) {
    
    this.elementTargets.forEach((el, i) => {
      el.classList.add("hidden")
    })
    
    if (event.target.checked) {
      this.elementTargets.forEach((el, i) => {
        let keys = el.getAttribute('data-key').split(',')
        if (keys.includes(event.target.value))
          el.classList.remove("hidden")
      })
    }
  }
}