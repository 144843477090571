import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["is_official", "cargo"]

  connect() {
    if (!this.is_officialTarget.checked) {
      this.cargoTarget.classList.add('hidden')
    }
  }

  official_change(event) {
    if (this.is_officialTarget.checked) {
      this.cargoTarget.classList.remove('hidden')
    } else {
      this.cargoTarget.classList.add('hidden')
    }
  }
}
